

import axios from "axios"
import {Dialog, Notify} from "vant"
import {errorDispose, getIsSocialize, loadAxios} from "@/Api/Config/propsUtil";

const Axios = axios.create()

loadAxios(Axios,"hand")

let userInfo:any = {}

Axios.interceptors.request.use( config=>{
    return getIsSocialize(config,"hand")
},error=>{
    Promise.reject(error)
} )

Axios.interceptors.response.use( response=>{
    return errorDispose(response,"hand")
},error=>{
    if ( error.message.includes('timeout') ){
        Notify({
            message: "请求超时",
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
    return Promise.reject(error)
} )

export default Axios;

// import {Second, ServerStatus} from "./url"
// import router from "@/router";
// import Storage from "@/util/Storage";
// import excludeList from "@/Api/Config/exclude";
// Axios.defaults.baseURL = Second
// Axios.defaults.headers.post["Content-Type"] = "application/json"
// Axios.defaults.headers.post["type"] = "wxpublic"
// Axios.defaults.timeout = 10 * 1000

// 请求前 参数处理
// try{
//     // 排除那些借口 不添加
//     let key = excludeList.findIndex(a=>new RegExp(a).test(config.headers.url))
//     if ( key < 0 ) return config;
//     // 默认添加 登录账号 school
//     if ( !(userInfo["school"] && typeof userInfo.school == "string" && userInfo.school.length) || userInfo.id != config.headers.userId ){
//         userInfo = JSON.parse(Storage.GetData_?.("UserData"))
//     }
//     let JsonData = typeof config.data == "string" ? JSON.parse(config.data as string) : config.data;
//     JsonData.school = userInfo.school || "";
//     config.data = JSON.stringify(JsonData);
// }catch (e) {
//     console.log(e+"添加学校参数失败")
// }
// return config

// 请求后 参数处理
// if ( response.data.message.code === "200" ){
//     return response.data
// }else{
//     if ( response.data.message.code === "401" ){
//         Dialog.confirm({
//             title: '温馨提示',
//             message: '登录过期请重新登录',
//             beforeClose(action, done) {
//                 if (action === 'confirm') {
//                     let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
//                     if ( bool != null && bool[0] == "micromessenger" ){
//                         if (ServerStatus == "test"){
//                             window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://test.mobile.31shequ.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
//                         } else window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://mobile.31shequ.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
//                     }else{
//                         router.replace({
//                             name:"register"
//                         })
//                     }
//                     done();
//                 } else {
//                     done();
//                 }
//             },
//         })
//     }else{
//         Notify({
//             message: response.data.message.msg,
//             color: 'white',
//             background: "rgba(0,0,0,.6)",
//         });
//     }
// }
