
import Axios from "../Config/hand"
import {
    HandAllUpData,
    HandIssueUpData
 } from "./interfaceType"


interface HeadType<T=string>{
    userId:T|number;
    token:T;
}

// 请求类型
export function HandType( headers:HeadType ):Promise<any>{
    return (
        Axios({
            url:"/app/smProduct/querySmCatalog",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 广场
export function HandUpAll( headers:HeadType,body:HandAllUpData ):Promise<any>{
    return (
        Axios({
            url:"/app/smProduct/searchSmProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 发布商品
export function HandIssue( headers:HeadType,body:HandIssueUpData ):Promise<any>{
    return (
        Axios({
            url:"/app/smProduct/addSmProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 商品详情
export function HandSuc( headers:HeadType,body:{ id:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/app/smProduct/getSmProductDetail",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 商品收藏
export function HandSucColl( headers:HeadType,body:{ id:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/app/smFollow/followProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改商品状态
export function HandChangeState( headers:HeadType,body:{ id:string|number,status:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/app/smProduct/updateSmProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除商品
export function HandRemove( headers:HeadType,body:{ id:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/app/smProduct/deleteSmProduct",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 我的发布列表
export function HandMyList( headers:HeadType,body:HandAllUpData ):Promise<any>{
    return (
        Axios({
            url:"/app/smProduct/getMySmProductList",
            method:"POST",
            headers,
            data:body
        })
    )
}
