
import store from "../../index"
import { VuexModule,getModule,Module,Mutation } from "vuex-module-decorators"
import { 
    HandTypeT,
    HandAllItem
 } from "@/Type/index"
 import { 
     HandAllUpData
 } from "@/Api/Hand/interfaceType"


interface ScrollNum<T=number>{
    ALLScrollNum:T;
    MyScrollNum:T;
}

interface IssueData<T=string>{
    Title:T;
    Content:T;
    ImgList:Array<string>;
    MoneyData:{
        Money:number | T;
        Price:number | T;
        Num:number | T;
        Fee:number | T;
        PackUp:boolean
    }
}

export interface HandType<T=string>{
    HandScroll:ScrollNum
    HandTypeList:Array<HandTypeT>
    HandAllList:Array<HandAllItem>
    HandleAllUpData:HandAllUpData
    HandleIssueData:IssueData
    HandIssueActiveT:HandTypeT
    HandMyList:Array<HandAllItem>
    HandMyUpData:HandAllUpData

    SetScrollNum(data:ScrollNum):void;
    SetHandTypeList(data:Array<HandTypeT>):void;
    SetHandAllList(data:Array<HandAllItem>):void;
    SetHandAllUpData(data:HandAllUpData):void;
    SetHandIssueData(data:IssueData):void;
    SetHandIssueActiveT( data:HandTypeT ):void;
    SetHandMyList(data:Array<HandAllItem>):void
    SetHandMyUpData(data:HandAllUpData):void;
}

@Module({
    name:"Hand",
    store,
    dynamic:true
})
export default class Hand extends VuexModule implements HandType{
    HandScroll:ScrollNum = {
        ALLScrollNum:0,
        MyScrollNum:0,
    }
    HandTypeList:Array<HandTypeT> = []
    HandAllList:Array<any> = []
    HandleAllUpData:HandAllUpData = {
        pageNo:1,
        pageSize:15
    }
    HandleIssueData:IssueData = {
        Title:"",
        Content:"",
        ImgList:[],
        MoneyData:{
            Money:"",
            Price:"",
            Num:1,
            Fee:"",
            PackUp:false
        }
    }
    HandIssueActiveT:HandTypeT = {}
    HandMyList:Array<HandAllItem> = []
    HandMyUpData:HandAllUpData = {
        pageNo:1,
        pageSize:15
    }

    // 缓存滚动值
    get GetHandScroll(){
        return this.HandScroll
    }
    @Mutation
    SetScrollNum(data:ScrollNum){
        this.HandScroll = data
    }
    // 类型
    get GetHandTypeList(){
        return this.HandTypeList
    }
    @Mutation
    SetHandTypeList(data:Array<HandTypeT>){
        this.HandTypeList = data
    }
    // 二手广场
    get GetHandAllList(){
        return this.HandAllList
    }
    @Mutation
    SetHandAllList(data:Array<HandAllItem>){
        this.HandAllList = data
    }
    // 广场 请求类型
    get GetHandleAllUpData(){
        return this.HandleAllUpData
    }
    @Mutation
    SetHandAllUpData(data:HandAllUpData){
        this.HandleAllUpData = data
    }
    // 二手发布
    get GetHandleIssueData(){
        return this.HandleIssueData
    }
    @Mutation
    SetHandIssueData(data:IssueData){
        this.HandleIssueData = data
    }
    // 选中类型
    get GetHandIssueActiveT(){
        return this.HandIssueActiveT
    }
    @Mutation
    SetHandIssueActiveT( data:HandTypeT ){
        this.HandIssueActiveT = data
    }
    // 我的发布列表
    get GetHandMyList(){
        return this.HandMyList
    }
    @Mutation
    SetHandMyList(data:Array<HandAllItem>){
        this.HandMyList = data
    }
    // 我的请求方式
    get GetHandMyUpData(){
        return this.HandMyUpData
    }
    @Mutation
    SetHandMyUpData(data:HandAllUpData){
        this.HandMyUpData = data
    }
}

export const HandStore = getModule( Hand )

